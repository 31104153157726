import APP_CONST from '@/constants/AppConst';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
@Component({
})
export default class ProgressButton extends Vue {
  public progressButtonWidth: number = 0;
  public buttonIndexReceived = '';

  @Prop({ default: 'Submit' })
  buttonText!: string;

  @Prop({ default: 600 })
  progressButtonDelay!: number;

  @Prop({ default: 0 })
  apiResponseCode!: number;

  @Prop({ default: '' })
  itemObj: any;

  @Prop({ default: true })
  isValidation!: boolean;

  @Prop({ default: false })
  showDelay!: boolean;

  @Prop({ default: '' })
  buttonIndex!: any;

  @Prop({ default: false })
  updateProgress!: boolean;

  @Prop({ default: true })
  disableButton!: boolean;

  @Prop({ type: Boolean, default: false }) disableTransition!: boolean;

  @Watch('apiResponseCode', { deep: true, immediate: true })
  apiResponse(val: any) {
    if (val == 200) {
      this.apiResponseCode = val;
      this.completeButton();
    } else if (val == 400) {
      this.reSetprogressBar();
    }
  }

  @Watch('isValidation', { deep: true, immediate: true })
  checkValidations() {
    this.buttonClick('', this.buttonIndex, 'fromWatcher');
  }

  buttonClick = (obj: any, buttonIndex: any, from: string) => {
    if (!this.isValidation) {
      this.progressButtonWidth = 0;
      this.tempDisableButton();
      this.buttonIndexReceived = buttonIndex;

      const percent = this.progressButtonDelay / 50;
      let i = 0;

      const interval = setInterval(() => {
        i++;
        if (i >= 80) {
          this.progressBar();
          clearInterval(interval);
          if (this.apiResponseCode == 200) {
            this.completeButton();
          } else if (this.apiResponseCode == 400) {
            clearInterval(interval);
            this.handleError(interval);
          } else {
            this.progressButtonWidth = i;
            if (!this.updateProgress) {
              this.setProgressResposeDelay();
            } else {
              this.progressBar();
            }
          }
        } else {
          if (this.apiResponseCode == 200) {
            clearInterval(interval);
            this.completeButton();
          } else if (this.apiResponseCode == 400) {
            clearInterval(interval);
            this.handleError(interval);
          } else {
            this.progressButtonWidth = i;
            this.progressBar();
          }
        }
      }, percent);
    }
    if (from != 'fromWatcher') {
      this.emitMethod(obj);
    }
  };

  handleError(interval: any) {
    clearInterval(interval);
    this.reSetprogressBar();
  }

  emitMethod(obj: any) {
    this.$emit('submitData', obj);
  }

  completeButton = () => {
    this.setProgressBeforeRespose();
  };

  complateButtonProgress() {
    const element: any = document.getElementById('progressBar' + this.buttonIndex);
    const style = `linear-gradient(to right, ${APP_CONST.STYLE.COLOR.PRIMARY_700}${' ' + 100 + '%'}, ${APP_CONST.STYLE.COLOR.NEUTRAL_100}${' ' + 100 + '%'})`;
    if (element) {
      element.style.background = style;
      element.classList.remove('disabled');
      element.classList.add('finished');
    }
    this.progressButtonWidth = 100;
    if (this.showDelay) {
      setTimeout(() => {
        this.reSetprogressBar();
        this.$emit('close');
      }, 100);
    } else {
      this.reSetprogressBar();
      this.$emit('close');
    }
  }

  tempDisableButton() {
    const element: any = document.getElementById('progressBar' + this.buttonIndex);
    if (element) {
      element.classList.add('disabled');
      element.classList.add('btn-secondary');
      element.classList.remove('btn-primary');
      element.classList.remove('finished');
      const style = `linear-gradient(to right, ${APP_CONST.STYLE.COLOR.PRIMARY_700}${' ' + 0 + '%'}, ${APP_CONST.STYLE.COLOR.NEUTRAL_100}${' ' + 0 + '%'})`;
      if (element.style) {
        element.style.background = style;
      }
    }
  }

  progressBar = () => {
    const newBttun: any = document.getElementById('progressBar' + this.buttonIndex);
    const style = `linear-gradient(to right, ${APP_CONST.STYLE.COLOR.PRIMARY_700}${' ' + this.progressButtonWidth + '%'}, ${APP_CONST.STYLE.COLOR.NEUTRAL_100}${' ' + this.progressButtonWidth + '%'})`;
    if (newBttun && newBttun.style) {
      newBttun.style.background = style;
    }
  };

  reSetprogressBar = () => {
    const element: any = document.getElementById('progressBar' + this.buttonIndex);
    if (element) {
      element.classList.remove('disabled');
      element.classList.remove('finished');
      element.classList.add('btn-primary');
      element.classList.remove('btn-secondary');
      const style = `linear-gradient(to right, ${APP_CONST.STYLE.COLOR.NEUTRAL_100}${' ' + 0 + '%'},  ${APP_CONST.STYLE.COLOR.PRIMARY_700}${' ' + 0 + '%'})`;
      element.style.background = style;
    }
    this.progressButtonWidth = 100;
  };

  setProgressBeforeRespose = () => {
    const interval = setInterval(() => {
      if (this.progressButtonWidth >= 100) {
        clearInterval(interval);
        this.complateButtonProgress();
      } else {
        if (this.apiResponseCode == 200) {
          this.completeButton();
          clearInterval(interval);
          this.progressButtonWidth = 100;
        } else
          if (this.apiResponseCode == 400) {
            clearInterval(interval);
            this.handleError(interval);
            this.progressButtonWidth = 100;
          }
          else {
            this.progressButtonWidth = this.progressButtonWidth + 5;
            this.progressBar();
          }
      }
    }, 200);
  };

  setProgressResposeDelay = () => {
    const interval = setInterval(() => {
      if (this.progressButtonWidth >= 96) {
        if (this.apiResponseCode == 200) {
          clearInterval(interval);
          this.completeButton();
        } else if (this.apiResponseCode == 400) {
          clearInterval(interval);
          this.handleError(interval);
        }
        else {
          this.progressBar();
        }
      } else {
        if (this.apiResponseCode == 200) {
          clearInterval(interval);
          this.completeButton();
        } else if (this.apiResponseCode == 400) {
          clearInterval(interval);
          this.handleError(interval);
        }
        else {
          this.progressButtonWidth = this.progressButtonWidth + 0.50;
          this.progressBar();
        }
      }
    }, 1000);
  };
}
